<template>
  <div class="search-box">
    <span class="search-box__icon">
      <toko-search-icon v-if="!localValue" />
      <button v-else class="btn btn--unset" @click="onClearSearch">x</button>
    </span>

    <input
      type="text"
      class="form-control search-box__input"
      :value="localValue"
      @input="$emit('input', $event.target.value)"
      @keydown.enter="$emit('on-search')"
      :placeholder="holderText"
    />
  </div>
</template>

<script>
import TokoSearchIcon from '@/ui/toko-icons/TokoSearch'

export default {
  name: 'SearchBox',

  data() {
    return {
      localValue: ''
    }
  },

  props: {
    value: {
      type: String
    },

    holderText: {
      type: String,
      default: 'Search'
    }
  },

  components: {
    TokoSearchIcon
  },

  methods: {
    onClearSearch() {
      this.localValue = ''
      this.$emit('input', this.localValue)
      this.$emit('on-search')
    }
  },

  created() {
    this.localValue = this.value
  },

  watch: {
    value(newValue) {
      this.localValue = newValue
    }
  }
}
</script>
